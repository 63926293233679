import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Box, ClickAwayListener, Fade, Popper } from "@mui/material";
import cn from "utils/cn";
import { Dayjs } from "dayjs";
import { ControllerRenderProps, UseFormWatch } from "react-hook-form";
import { MdOutlineDateRange } from "react-icons/md";
import CreateFormStateTypes from "pages/createAds/CreateAdsFormValidationSchema";
import 'dayjs/locale/de';

interface Props {
  watch?: UseFormWatch<CreateFormStateTypes>;
  field?: ControllerRenderProps<CreateFormStateTypes, any>;
  placeholder?: string;
  disable?: boolean;
}

const Datepicker: React.FC<Props> = ({
  field,
  watch,
  placeholder,
  disable,
}) => {
  /**-- state --*/
  // option open state
  const [calenderOpen, setCalenderOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const parentRef = React.useRef<null | HTMLDivElement>(null);

  // value state
  const [date, setDate] = React.useState<Dayjs | null>(null);

  /**-- handlers --*/
  // handle selection option optn
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setCalenderOpen((previousOpen) => !previousOpen);
  };

  /**-- variables --*/
  const canBeOpen = calenderOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <ClickAwayListener onClickAway={() => setCalenderOpen(false)}>
      <div className="flex w-full relative" ref={parentRef}>
        {/* date inputs */}
        <button
          className={cn(
            "w-full flex items-center justify-between rounded-[4px] border-[1px] py-2 px-3 border-[#D1D5DB] text-sm capitalize leading-6",
            calenderOpen &&
            "border-[#237804] bg-[#EAFFEC] text-[#237804] !font-bold",
            disable && "bg-[#eceeec]"
          )}
          aria-describedby={id}
          type="button"
          onClick={handleOpen}
          disabled={disable}
        >
          <span
            className={cn(
              field?.value
                ? "text-[#111827]"
                : calenderOpen
                  ? "text-[#237804]"
                  : "text-[#9CA3AF]"
            )}
          >
            {field?.value ? field?.value?.format("DD/MM/YYYY") : placeholder}
          </span>
          <span
            className={cn(calenderOpen ? "text-[#237804]" : "text-[#6B7280]")}
          >
            {/* {calenderOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />} */}
            <MdOutlineDateRange className="h-5 w-5" />
          </span>
        </button>

        <Popper
          id={id}
          open={calenderOpen}
          anchorEl={anchorEl}
          transition
          sx={{
            width: parentRef?.current?.offsetWidth,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  boxShadow:
                    "1px 6px 15px -3px rgba(0, 0, 0, 0.10), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)",
                  // paddingX: "8px",
                  bgcolor: "background.paper",
                  overflow: "hidden",
                  borderRadius: "4px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                  <DateCalendar
                    value={date}
                    onChange={(newValue: Dayjs) => {
                      setDate(newValue);
                    }}
                    sx={{
                      width: "100%",
                      height: "auto !important",
                      "& .MuiPickersSlideTransition-root": {
                        minHeight: "200px",
                      },
                      "& .MuiDayCalendar-header": {
                        justifyContent: "space-around",
                      },
                      "& .MuiDayCalendar-weekContainer": {
                        justifyContent: "space-around",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#237804 !important",
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: "#237804 !important",
                      },
                    }}
                  />
                </LocalizationProvider>
                {/* action button */}
                <div
                  className="flex items-center justify-between gap-5 py-4 px-5"
                  style={{
                    boxShadow:
                      "0px 7.581px 11.372px -2.274px rgba(0, 0, 0, 0.10), 0px -3.033px 4.549px -1.516px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <button
                    className="capitalize  text-[#9CA3AF] rounded-[4px] !font-bold leading-6 text-[16px]"
                    onClick={() => {
                      field?.onChange(undefined);
                      setCalenderOpen(false);
                    }}
                  >
                    Clear
                  </button>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="capitalize py-1 px-4 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                      onClick={() => setCalenderOpen(false)}
                    >
                      Cancel
                    </button>

                    <button
                      type={"button"}
                      className={cn(
                        " py-1 px-4.5 bg-am-primary text-white rounded-[4px] text-[16px] leading-6 font-bold"
                      )}
                      onClick={() => {
                        field?.onChange(date);
                        setCalenderOpen(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Box>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default Datepicker;
