import { Modal } from "@mui/material";
import SubmitButton from "components/submitButton/SubmitButton";
import warningIcon from "assets/icons/warning.svg";
import { TabType } from "./CreateAds.types";

const Warning = ({
  open,
  setOpen,
  setActiveTab,
  dataFilledFor,
  handleContinue,
  isLoading,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<TabType>>;
  dataFilledFor?: "Auto" | "Manual" | "",
  handleContinue?: () => void,
  isLoading
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="w-full md:w-[650px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg py-6 px-8">
        <div className="flex flex-col items-center lg:w-[85%] mx-auto gap-4 mb-4">
          {/* icon */}
          <div className="flex items-center justify-center">
            <div className="inline-flex p-4 rounded-full bg-[#FFF8C4]">
              <img src={warningIcon} alt="warning" />
            </div>
          </div>
          <h4 className="text-center">
            Are You Sure You Want to Set Only the {dataFilledFor} Campaign?
          </h4>
          <p className="text-center">
            If you proceed, the campaign will be created using only the {dataFilledFor === "Auto" ? "Manual" : "Auto"}
            campaign type.
          </p>
        </div>

        {/* action button */}
        <div className="w-full flex items-center justify-center px-5 py-3">
          <div className="flex items-center justify-end gap-5">
            <button
              type="button"
              className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
              onClick={() => {
                setActiveTab(dataFilledFor === "Auto" ? TabType.MANUAL_CAMPAIGN : TabType.AUTO_CAMPAIGN);
                setOpen(false);
              }}
            >
              Go to {dataFilledFor === "Auto" ? "Manual" : "Auto"} Campaign
            </button>
            <div>
              <SubmitButton
                buttonText="Continue"
                buttonType="button"
                className="px-8"
                loadingClassName="px-8"
                disabledClassName="px-8"
                isDisabled={false}
                isLoading={false}
                onClick={handleContinue}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Warning;
