import Datepicker from "./Datepicker";
import ChoosePortfolio from "./ChoosePortfolioInput";
import {
  Control,
  Controller,
  ControllerRenderProps,
  useFormContext,
  UseFormWatch,
} from "react-hook-form";
import { TabType } from "pages/createAds/CreateAds.types";
import { FC } from "react";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import CreateFormStateTypes from "pages/createAds/CreateAdsFormValidationSchema";

interface Props {
  tabType: TabType;
}

const DropdownInputs: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control, watch } = useFormContext<CreateFormStateTypes>()

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <SelectOption
        datePicker={false}
        title="Portfolio"
        placeholder="Choose portfolio"
      />
      <Controller
        name={`${tabType}.startDate`}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <SelectOption
                datePicker={true}
                title="Start Date"
                placeholder="Select date"
                field={field}
                watch={watch}
              />
              {error?.message && <ErrorLabel message={error?.message} />}
            </div>
          )
        }}
      />
      <Controller
        name={`${tabType}.endDate`}
        control={control}
        render={({ field }) => (
          <SelectOption
            datePicker={true}
            title="End Date"
            placeholder="Select date"
            hasSelectOption={true}
            watch={watch}
            field={field}
            control={control}
            tabType={tabType}
          />
        )}
      />
    </div>
  );
};

const SelectOption = ({
  placeholder,
  title,
  datePicker,
  hasSelectOption,
  field,
  watch,
  control,
  tabType,
}: {
  title: string;
  placeholder: string;
  datePicker: boolean;
  hasSelectOption?: boolean;
  watch?: UseFormWatch<CreateFormStateTypes>;
  field?: ControllerRenderProps<CreateFormStateTypes, any>;
  control?: Control<CreateFormStateTypes, any>;
  tabType?: TabType;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-[#111827] !font-bold leading-6 text-[16px]">
        {title}
      </h3>
      <div className="">
        {datePicker ? (
          <>
            <Datepicker
              placeholder={placeholder}
              field={field}
              watch={watch}
              disable={
                watch && tabType && watch(`${tabType}.noEndDate`)
              }
            />
            {hasSelectOption && tabType && (
              <Controller
                name={`${tabType}.noEndDate`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="inline-flex items-center gap-2 mt-3">
                    <input
                      onChange={(_) => {
                        onChange(!value);
                      }}
                      type="checkbox"
                      checked={value}
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label>No End Date</label>
                  </div>
                )}
              />
            )}
          </>
        ) : (
          <>
            <ChoosePortfolio placeholder="Choose portfolio" />
          </>
        )}
      </div>
    </div>
  );
};

export default DropdownInputs;
