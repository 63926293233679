import { DefaultBid } from 'constants/index';
import * as yup from 'yup';

const nameSchema = yup.object({
  name: yup.string().required("please provide a name"),
  placeholders: yup.array().of(yup.object({ placeholderType: yup.string(), value: yup.string() }))
})

// export enum CampaignStructureEnum {
//   "loose" = "QUERY_BROAD_REL_MATCHES",
//   "close" = "QUERY_HIGH_REL_MATCHES",
//   "complements" = "ASIN_ACCESSORY_RELATED",
//   "substitues" = "ASIN_SUBSTITUTE_RELATED"
// }

const campaignStructureTypeSchema = yup.string().oneOf(["SingleCampaignWithSingleAdgroup", "SingleCampaignWithMultipleAdgroup", "MultipleCampaignWithSingleAdgroup"]).required()

const budgetSchema = yup.object({
  budgetType: yup.string().oneOf(["DAILY"]),
  budgetAmount: yup.number().typeError("please provide a budget").min(1, "the minimum budget must be at least $1").required("please provide a budget")
})
const bidSchema = yup.number().typeError("please provide a bid").min(DefaultBid, "the minimum bid must be at least $0.02").required("please provide a bid")

const placementTypes = yup.string().oneOf(["PLACEMENT_TOP", "PLACEMENT_PRODUCT_PAGE", "PLACEMENT_REST_OF_SEARCH"])

const placementSchema = yup.object({
  placement: placementTypes,
  percentage: yup.number().integer("floating point numbers are not allowed").min(1, "percentage should be between 1-900").max(900, "percentage should be between 1-900").nullable().transform((value, originalValue) => originalValue === '' ? null : value)
})

const dynamicBiddingSchema = yup.object({
  placementBidding: yup.array().of(placementSchema),
  strategy: yup.string().nullable().oneOf(["LEGACY_FOR_SALES", "AUTO_FOR_SALES", null]) // Down Only, Up and Down, Fixed
})

export enum AutoMatchTypeEnums {
  "loose" = "QUERY_BROAD_REL_MATCHES",
  "close" = "QUERY_HIGH_REL_MATCHES",
  "complements" = "ASIN_ACCESSORY_RELATED",
  "substitues" = "ASIN_SUBSTITUTE_RELATED"
}

const autoMatchTypeSchema = yup.array().of(yup.object({
  expression: yup.string().oneOf(Object.values(AutoMatchTypeEnums)).typeError("Enum Didn't match"), //loose match, close match, complements, substitutes
  bid: yup.number().min(1, "the multipler must be at least 1").nullable().transform((value, originalValue) => originalValue === '' ? null : value),
}))

const manualMatchTypeSchema = yup.object({
  broad: yup.object({
    selected: yup.boolean(),
    value: yup
      .number()
      .min(1, "the multipler must be at least 1")
      .nullable()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .typeError("Please provide digit.")
  }),
  phrase: yup.object({
    selected: yup.boolean(),
    value: yup.number().min(1, "the multipler must be at least 1").nullable().transform((value, originalValue) => originalValue === '' ? null : value).typeError("Please provide digit."),
  }),
  exact: yup.object({
    selected: yup.boolean(),
    value: yup.number().min(1, "the multipler must be at least 1").nullable().transform((value, originalValue) => originalValue === '' ? null : value).typeError("Please provide digit."),
  }),
  product: yup.object({
    selected: yup.boolean(),
    value: yup.number().min(1, "the multipler must be at least 1").nullable().transform((value, originalValue) => originalValue === '' ? null : value).typeError("Please provide digit."),
  }),
  category: yup.object({
    selected: yup.boolean(),
    value: yup.number().min(1, "the multipler must be at least 1").nullable().transform((value, originalValue) => originalValue === '' ? null : value).typeError("Please provide digit."),
  }),
})

const keywordTargetObjectSchema = yup.object({
  id: yup.number(),
  matchType: yup.string().oneOf(["BROAD", "PHRASE", "EXACT"]),
  bid: bidSchema,
  keywordText: yup.string()
})

const keywordTargetsSchema = yup.array().of(keywordTargetObjectSchema).min(1, "Please select at least 1 keyword target")

const marketPlacesSchema = yup.array().of(yup.object({
  region: yup.string(),
  marketplaces: yup.array().of(yup.object({ countryCode: yup.string(), n: yup.number() }))
}))

export enum ProductTargetingExpressionsTypes {
  "ASIN_CATEGORY_SAME_AS" = "ASIN_CATEGORY_SAME_AS",
  "exact" = "ASIN_SAME_AS",
  "expanded" = "ASIN_EXPANDED_FROM"
}

const productTargetingExpressionSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  expression: yup.array().of(yup.object({
    type: yup.string().oneOf(Object.values(ProductTargetingExpressionsTypes)),
    value: yup.string()
  })),
  bid: bidSchema
})
const productTargetingSchema = yup.array().of(productTargetingExpressionSchema)

export enum ProductMatchTypesEnums {
  "exact" = "ASIN_SAME_AS",
  "expanded" = "ASIN_EXPANDED_FROM"
}
const keywordMatchTypesFilters = yup.object({ broad: yup.boolean(), phrase: yup.boolean(), exact: yup.boolean() })
const productMatchTypesFilters = yup.array().of(yup.string().oneOf(Object.keys(ProductMatchTypesEnums)))

export const createAdsValidationSchema = yup.object({
  keywordMatchTypesFilters: keywordMatchTypesFilters,
  productMatchTypesFilters: productMatchTypesFilters,
  autoCampaign: yup.object({
    campaignName: nameSchema,
    adGroupName: nameSchema,
    campaignStructureType: campaignStructureTypeSchema,
    budget: budgetSchema,
    dynamicBidding: dynamicBiddingSchema,
    defaultBid: bidSchema,
    startDate: yup.string().required("please provide the start date."),
    endDate: yup.string(),
    noEndDate: yup.boolean(),
    customBid: yup.number().typeError("please provide a bid").transform((originalValue) => originalValue === 0 ? null : originalValue).min(DefaultBid, "the minimum bid must be at least $0.02").notRequired().nullable(),
    autoCampaignMatchTypes: autoMatchTypeSchema,
    marketPlaces: marketPlacesSchema
  }),
  //manual
  manualCampaign: yup.object({
    types: yup.object({ keywordsTargeting: yup.boolean(), productTargeting: yup.boolean() }).required(),
    campaignName: nameSchema,
    adGroupName: nameSchema,
    campaignStructureType: campaignStructureTypeSchema,
    budget: budgetSchema,
    dynamicBidding: dynamicBiddingSchema,
    defaultBid: bidSchema,
    startDate: yup.string().required("please provide the start date."),
    endDate: yup.string(),
    noEndDate: yup.boolean(),
    customBid: yup.number().typeError("please provide a bid").transform((originalValue) => originalValue === 0 ? null : originalValue).min(DefaultBid, "the minimum bid must be at least $0.02").notRequired().nullable(),
    keywordTargetsMultiplier: manualMatchTypeSchema,
    keywordTargets: keywordTargetsSchema.required("Please select at least 1 keyword target"),
    targetingClauses: productTargetingSchema,
    marketPlaces: marketPlacesSchema
  })
})

export type ProductTargetingCluasesInterface = yup.InferType<typeof productTargetingExpressionSchema>

export type KeywordTargetsTypes = yup.InferType<typeof keywordTargetObjectSchema>

export type KeywordMatchTypesFiltersTypes = yup.InferType<typeof keywordMatchTypesFilters>

export type PlacementTypes = yup.InferType<typeof placementTypes>;

type CreateFormStateTypes = yup.InferType<typeof createAdsValidationSchema>;

export default CreateFormStateTypes