import deleteIcon from "assets/icons/delete_icon.svg";
import CreateFormStateTypes, { KeywordTargetsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface SelectedKeywordRowSchema {
  keyword: KeywordTargetsTypes
}

const SelectedKeywordRow: FC<SelectedKeywordRowSchema> = ({ keyword }) => {
  /**-Hook Form-**/
  const { control, getValues } = useFormContext<CreateFormStateTypes>()
  const { remove } = useFieldArray({
    control: control,
    name: "manualCampaign.keywordTargets"
  })

  /**-Event Handlers-**/
  const handleRemoveKeywordTarget = (keyword: KeywordTargetsTypes): void => {
    const index = getValues("manualCampaign.keywordTargets")?.findIndex((target) => target?.id === keyword?.id && target?.matchType?.toLowerCase() === keyword?.matchType?.toLowerCase());
    remove(index);
  }

  return (
    <div className="grid grid-cols-3">
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        {keyword?.keywordText}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        <span className="capitalize">{(keyword?.matchType || "").toLocaleLowerCase()}</span>
      </div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-end">
        <button
          onClick={() => handleRemoveKeywordTarget(keyword)}
          type="button"
        >
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedKeywordRow;
