
import { Dayjs } from "dayjs";

export enum TabType {
  AUTO_CAMPAIGN = "autoCampaign",
  MANUAL_CAMPAIGN = "manualCampaign",
}

export enum CampaignStructureType {
  TYPE_1 = "SingleCampaignWithSingleAdgroup",
  TYPE_2 = "SingleCampaignWithMultipleAdgroup",
  TYPE_3 = "MultipleCampaignWithSingleAdgroup",
}

export enum PlaceholderType {
  CAMPAIGN_STRUCTURE = "Campaign Structure",
  TARGETING_TYPE = "Targeting Type",
  MATCH_TYPE = "Match Type",
  ASIN = "ASIN",
  CUSTOM_TEXT = "Custom Text"
}

export interface CreateFormState {
  autoCampaign: {
    campaignName: CampaignNameType;
    adGroupName: AdGroupNameType;
    campaignStructureType: CampaignStructureType;
    timeline: Timeline;
    bidSettings: BidSettings,
    matchType: AutoCampaignMatchType,
    placement: Placement,
    marketPlaces: Marketplace[];
  };
  manualCampaign: {
    types: {
      keywordsTargeting: number;
      productTargeting: number;
    };
    campaignName: CampaignNameType;
    adGroupName: AdGroupNameType;
    campaignStructureType: CampaignStructureType;
    timeline: Timeline;
    bidSettings: BidSettings;
    matchType: ManualCampaignMatchType,
    placement: Placement,
    marketPlaces: Marketplace[];
  };
}

export interface Placement {
  topOfSearch: number | string,
  productDetailPages: number | string,
  restOfSearch: number | string
}

export interface AutoCampaignMatchType {
  targetingBit: number | string,
  close: {
    selected: boolean,
    value: number | string
  },
  loose: {
    selected: boolean,
    value: number | string
  },
  subsitutes: {
    selected: boolean,
    value: number | string
  },
  complements: {
    selected: boolean,
    value: number | string
  }
}
export interface ManualCampaignMatchType {
  targetingBit: number | string,
  broad: {
    selected: boolean,
    value: number | string
  },
  phrase: {
    selected: boolean,
    value: number | string
  },
  exact: {
    selected: boolean,
    value: number | string
  },
  product: {
    selected: boolean,
    value: number | string
  },
  category: {
    selected: boolean,
    value: number | string
  }
}

export interface BidSettings {
  campaignBiddingStrategy: CampaignBiddingStrategy,
  dailyBudget: number | string,
  defaultBid: number | string,
}

export enum CampaignBiddingStrategy {
  DOWN_ONLY = "Down only",
  UP_AND_DOWN = "Up and down",
  FIXED = "Fixed"
}
export interface Timeline {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  noEndDate: boolean;
}

export interface CampaignNameType {
  name: string;
  placeholders: { placeholderType: PlaceholderType, value: string }[];
}

export interface AdGroupNameType {
  name: string;
  placeholders: { placeholderType: PlaceholderType, value: string }[];
}

export interface Marketplace {
  region: string;
  marketplaces: { countryCode: string; n: number }[];
}


/**------------New---------------------*/
export interface MarketPlacesInterface {
  profileId: string, // Required: Non-nullable (string)
  portfolioId: number, // This field is not in the CampaignDTO class; assumed nullable or optional.
  countryCode: string // Required: Non-nullable (string)
}

export interface DynamicBiddingInterface { // Required: Non-nullable (DynamicBidding)
  placementBidding: [
    {
      placement: "PLACEMENT_TOP" | "PLACEMENT_PRODUCT_PAGE" | "REST_OF_SEARCH", // Required: Non-nullable (string or enum)
      percentage: number // Required: Non-nullable (double)
    }
  ],
  strategy: "LEGACY_FOR_SALES" | "AUTO_FOR_SALES" | null // Required: Non-nullable (string or enum)
}

export interface BudgetInterface { // Required: Non-nullable (Budget)
  budgetType: string, // Required: Non-nullable (string or enum)
  budgetAmount: 0 // Required: Non-nullable (double)
}

export interface AdGroupInterface {
  name: string, // Required: Non-nullable (string)
  defaultBid: number // Required: Non-nullable (double)
}

export interface AutoMatchTypes {
  expression: "QUERY_BROAD_REL_MATCHES" | "QUERY_HIGH_REL_MATCHES" | "ASIN_ACCESSORY_RELATED" | "ASIN_SUBSTITUTE_RELATED", // Required: Non-nullable (string)
  bid: number // Required: Non-nullable (double)
}

export interface KeywordTargetInterface {
  matchType: "BROAD" | "PHRASE" | "EXACT", // Required: Non-nullable (string or enum)
  bid: number, // Required: Non-nullable (double)
  keywordText: string // Required: Non-nullable (string)
}

export interface TargetingClausesInterface {
  expression: [ // Required: Non-nullable (List<TargetingExpression>)
    {
      type: "ASIN_CATEGORY_SAME_AS" | "ASIN_SAME_AS" | "ASIN_EXPANDED_FROM", // Required: Non-nullable (string or enum)
      value: string // Required: Non-nullable (string)
    }
  ],
  bid: number // Required: Non-nullable (double)
}

export interface AutoCampaignInterface {
  targetingType: "AUTO",
  name: string, // Required: Non-nullable (string)
  startDate: string, // Required: Non-nullable (DateTime)
  endDate: string, // Optional: Nullable (DateTime?)
  defaultBid: number, // Required: Non-nullable (double)
  customBid: number, // Optional: Nullable (double?)
  marketPlaces: MarketPlacesInterface[],
  dynamicBidding: DynamicBiddingInterface,
  budget: BudgetInterface,
  adGroups: AdGroupInterface[],
  autoCampaignMatchTypes: AutoMatchTypes[],
  asin: [ // Required: Non-nullable (List<string>)
    string // Required: Non-nullable (string)
  ],
  keywordTargets: KeywordTargetInterface[]
  targetingClauses: TargetingClausesInterface[],
  isCreated: true // Required: Non-nullable (bool)
}

export interface ManualCampaignInterface {
  targetingType: "MANUAL",
  name: string, // Required: Non-nullable (string)
  startDate: string, // Required: Non-nullable (DateTime)
  endDate: string, // Optional: Nullable (DateTime?)
  defaultBid: number, // Required: Non-nullable (double)
  customBid: number, // Optional: Nullable (double?)
  marketPlaces: MarketPlacesInterface[],
  dynamicBidding: DynamicBiddingInterface,
  budget: BudgetInterface,
  adGroups: AdGroupInterface[],
  autoCampaignMatchTypes: AutoMatchTypes[],
  asin: [ // Required: Non-nullable (List<string>)
    string // Required: Non-nullable (string)
  ],
  keywordTargets: KeywordTargetInterface[]
  targetingClauses: TargetingClausesInterface[],
  isCreated: true // Required: Non-nullable (bool)
}