import React, { FC } from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import { ProductInterface } from "./IndividualProductsTab";
import { useFormContext } from "react-hook-form";
import CreateFormStateTypes, { ProductTargetingExpressionsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { Checkbox } from "@mui/material";

interface Props {
  product: ProductInterface;
  handleProductSelectionChange: (product: ProductInterface) => void
}

const ProductRow: FC<Props> = ({ product, handleProductSelectionChange }) => {
  /**-Hook Form-**/
  const { watch } = useFormContext<CreateFormStateTypes>()
  const watchProductIndviTargets = watch("manualCampaign.targetingClauses")
  const checked = watchProductIndviTargets?.some((prod) => prod?.id === product?.id && prod?.expression?.[0].type === ProductTargetingExpressionsTypes[`${product?.type}`])

  return (
    <div className="grid grid-cols-5 gap-2">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px] flex gap-2">
        {/* product image */}
        <img
          src="/assets/images/book1.svg"
          alt="book"
          className="w-[35px] object-cover"
        />
        {/* product details */}
        <div className="flex flex-col justify-center gap-1  ">
          <h4 className="line-clamp-1 text-[#111827] !font-medium !m-0 leading-5 text-sm">
            {product?.name}
          </h4>
          <div className="flex items-center gap-2 !m-0 font-normal leading-5 text-xs">
            <span>{product?.author}</span>
            <span className="w-[8px] h-[8px] rounded-full bg-[#b1b8c4] block m-0 p-0" />
            <span>{product?.asin}</span>
          </div>
        </div>
      </div>
      <div className="text-black leading-6 text-[16px] capitalize">
        {product?.type}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <Checkbox
          icon={<IoIosAddCircleOutline />}
          checkedIcon={<GoCheckCircleFill />}
          onChange={() => handleProductSelectionChange(product)}
          checked={checked || false}
          sx={{
            '&.Mui-checked': {
              color: "green"
            }
          }}
          size='large'
        />
      </div>
    </div>
  );
};

export default ProductRow;
