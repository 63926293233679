import React from "react";
import cn from "utils/cn";

const ErrorLabel = ({
  message = "required",
  className,
}: {
  message?: string;
  className?: string;
}) => {
  return <p className={cn("text-red-500 m-0 text-sm", className)}>{message}</p>;
};

export default ErrorLabel;
