import {
  Controller,
  useFormContext,
} from "react-hook-form";
import { TabType } from "../CreateAds.types";
import { FC } from "react";
import CampaignNameInput from "../shared/campaignNameInput/CampaignNameInput";
import AdGroupNameInput from "../shared/adGroupNameInput/AdGroupNameInput";
import CampaignStructureOptions from "../shared/campaignStructureOption/CampaignStructureOptions";
import DropdownInputs from "../shared/dropdownInputs/DropdownInputs";
import BidSettings from "../shared/bidSettings/BidSettings";
import MatchTypeInputs from "../shared/matchTypeInputs/MatchTypeInputs";
import PlacementInputs from "../shared/placementInputs/PlacementInputs";
import SelectMarketPlacesInputs from "../shared/selectMarketPlacesInputs/SelectMarketPlacesInputs";
import NegativeTargetingInputs from "../shared/negativeTargetingInputs/NegativeTargetingInputs";
import { marketplaces } from "../data";
import ProductTargetingInputs from "./productTargetingInputs/ProductTargetingInputs";
import KeywordTargetingInputs from "./keywordTargetingInputs/KeywordTargetingInputs";
import CreateFormStateTypes from "../CreateAdsFormValidationSchema";

interface Props {
  tabType: TabType;
}

const CreateAdsManualCampaignTab: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control } = useFormContext<CreateFormStateTypes>()
  return (
    <div className="flex flex-col gap-5">
      {/* type selection */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="text-[#111827] leading-6 font-bold text-[16px] !m-0">
          Types
        </h4>
        <div className="flex gap-4">
          <Controller
            name={`manualCampaign.types.keywordsTargeting`}
            control={control}
            render={({ field }) => (
              <div className="flex items-center justify-start gap-2">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      field?.onChange(true);
                    } else {
                      field?.onChange(false);
                    }
                  }}
                  checked={Boolean(field?.value)}
                  value={field?.value ? 1 : 0}
                  id="manualCampaign.types.keywordsTargeting"
                  type="checkbox"
                  className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                />
                <label
                  className="text-[#111827] text-[16px] !font-normal leading-6"
                  htmlFor="manualCampaign.types.keywordsTargeting"
                >
                  Keywords Targeting
                </label>
              </div>
            )}
          />
          <Controller
            name={`manualCampaign.types.productTargeting`}
            control={control}
            render={({ field }) => (
              <div className="flex items-center justify-start gap-2">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      field?.onChange(true);
                    } else {
                      field?.onChange(false);
                    }
                  }}
                  checked={Boolean(field?.value)}
                  value={field?.value ? 1 : 0}
                  type="checkbox"
                  id="manualCampaign.types.productTargeting"
                  className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                />
                <label
                  className="text-[#111827] text-[16px] !font-normal leading-6"
                  htmlFor="manualCampaign.types.productTargeting"
                >
                  Product Targeting
                </label>
              </div>
            )}
          />
        </div>
      </div>
      {/* campaign name input */}
      <CampaignNameInput
        tabType={tabType}
      />
      {/* ad group name */}
      <AdGroupNameInput
        tabType={tabType}
      />
      {/* campaign structure option */}
      <CampaignStructureOptions
        tabType={tabType}
      />
      {/* dropdown select options */}
      <DropdownInputs
        tabType={tabType}
      />
      {/* divider */}
      <div className="w-full h-[3px] bg-[#E5E7EB]" />
      {/* bid settings */}
      <BidSettings
        tabType={tabType}
      />
      {/* match types */}
      <MatchTypeInputs
        tabType={tabType}
      />
      {/* keyword targeting inputs */}
      <KeywordTargetingInputs />
      {/* product targetting */}
      <ProductTargetingInputs />
      {/* divider */}
      <div className="w-full h-[3px] bg-[#E5E7EB]" />
      {/* placement inputs */}
      <PlacementInputs
        tabType={tabType}
      />
      {/* divider */}
      <div className="w-full h-[3px] bg-[#E5E7EB] " />
      {/* marketplaces */}
      <SelectMarketPlacesInputs
        tabType={tabType}
        marketplace={marketplaces}
      />
      {/* divider */}
      <div className="w-full h-[3px] bg-[#E5E7EB] " />
      {/* target inputs */}
      <NegativeTargetingInputs />
    </div>
  );
};

export default CreateAdsManualCampaignTab;
