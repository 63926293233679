import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { RxDragHandleDots2 } from "react-icons/rx";
import { Placeholders } from "../../data";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import {
  CreateFormState,
  PlaceholderType,
  TabType,
} from "pages/createAds/CreateAds.types";
import { FC } from "react";
import { IoMdClose } from "react-icons/io";
import ErrorLabel from "components/errorLabel/ErrorLabel";
// import { IoMdClose } from "react-icons/io";

interface Props {
  tabType: TabType;
}

const AdGroupNameInput: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control, getValues, watch, formState: { errors } } = useFormContext<CreateFormState>()
  return (
    <div className="flex flex-col gap-1">
      <label htmlFor="campaign-name" className="flex flex-col">
        <span className="text-[#111827] leading-6 font-bold text-[16px]">
          Ad Group Name
        </span>{" "}
        <p className="text-[#111827] !m-0 !font-normal text-[16px] leading-6 ">
          You can set dynamic ad group names by applying placeholders.
        </p>
      </label>

      <div className="w-full px-3 border-[1px] mt-1 py-[2px] flex items-center rounded-[4px] gap-3 flex-wrap">
        {/* input campaign name */}

        <Controller
          name={`${tabType}.adGroupName.name`}
          control={control}
          render={({ field, fieldState }) => (
            <div className="inline-flex flex-col gap-1">
              <div className="inline-flex items-center bg-[#F9FAFB] rounded-sm p-1 gap-[5px]">
                <span>
                  <RxDragHandleDots2 className="h-5 w-5" />
                </span>
                <input
                  {...field}
                  type="text"
                  placeholder="Enter campaign name..."
                  className="outline-none bg-transparent py-[2px]"
                />
              </div>
            </div>
          )}
        />
        {/* placeholder selection option */}
        {getValues(`${tabType}.adGroupName.placeholders`)?.map((value, idx) => {
          if (
            getValues(
              `${tabType}.adGroupName.placeholders.${idx}.placeholderType`
            ) === PlaceholderType.CUSTOM_TEXT
          ) {
            return (
              <Controller
                name={`${tabType}.adGroupName.placeholders`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handlerDelete = () => {
                    let field = structuredClone(value);
                    field.splice(idx, 1);
                    onChange(field);
                  };

                  return (
                    <>
                      <div className="w-[16px] h-[2px] bg-[#A1A1AA]" />
                      {/* background removed from design:  border-[#237804] border-[1px]*/}
                      <div className="w-[200px] rounded-[4px] leading-6 flex justify-between relative items-center">
                        <div className="inline-flex flex-col gap-1 relative">
                          <div className="inline-flex items-center bg-[#F9FAFB] rounded-sm p-1 gap-[5px]">
                            <span>
                              <RxDragHandleDots2 className="h-5 w-5" />
                            </span>
                            <Controller
                              name={`${tabType}.adGroupName.placeholders.${idx}.value`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                  value={
                                    field?.value === PlaceholderType.CUSTOM_TEXT
                                      ? ""
                                      : field?.value
                                  }
                                  type="text"
                                  placeholder="Enter custom text..."
                                  className="outline-none bg-transparent py-[2px]"
                                />
                              )}
                            />
                            <button
                              className=" absolute right-0 top-[50%] translate-y-[-50%] px-2"
                              type="button"
                              onClick={() => handlerDelete()}
                            >
                              <IoMdClose className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }}
              />
            );
          }
          return (
            <Controller
              name={`${tabType}.adGroupName.placeholders`}
              control={control}
              render={({ field: { onChange, value } }) => {
                const handlerDelete = () => {
                  let field = structuredClone(value);
                  field.splice(idx, 1);
                  onChange(field);
                };
                return (
                  <>
                    <div className="w-[16px] h-[2px] bg-[#A1A1AA]" />
                    {/* background removed from design:  border-[#237804] border-[1px]*/}
                    <div className="w-[200px] py-[3px] px-[10px]  bg-[#EAFFEC] text-[#237804] rounded-[4px] leading-6 flex justify-between relative items-center">
                      <span>
                        <RxDragHandleDots2 className="h-5 w-5" />
                      </span>
                      <p className="!m-0 line-clamp-1">{value[idx].value}</p>
                      <button
                        className=" px-2"
                        type="button"
                        onClick={() => handlerDelete()}
                      >
                        <IoMdClose className="h-5 w-5" />
                      </button>
                    </div>
                  </>
                );
              }}
            />
          );
        })}

        {watch(`${tabType}.adGroupName.placeholders`)?.length > 0 && (
          <div className="w-[16px] h-[2px] bg-[#A1A1AA]" />
        )}
        <Controller
          name={`${tabType}.adGroupName.placeholders`}
          control={control}
          render={({ field }) => {
            const change = (value: any) => {
              let lst = [...field.value];
              lst.push({
                placeholderType: value,
                value: value,
              });
              field.onChange(lst);
            };
            return (
              <>
                <div className="w-[200px]">
                  <CustomSelectOption
                    field={{
                      onChange: change,
                      value: "Add Placeholder",
                      onBlur: field.onBlur,
                    }}
                    options={Placeholders}
                    placeholder="Add Placeholders"
                    sx={{
                      // backgroundColor: "#EAFFEC",
                      "& .MuiSelect-select.MuiSelect-outlined": {
                        padding: "3px 10px !important",
                      },
                      "& .MuiSelect-iconOutlined": {
                        color: "#237804",
                      },
                    }}
                    valueCalss="!text-[#237804] !border-[#237804]"
                  />
                </div>
              </>
            );
          }}
        />
      </div>
      {errors[`${tabType}`]?.adGroupName?.name?.message && <ErrorLabel message={errors[`${tabType}`]?.adGroupName?.name?.message} />}
    </div>
  );
};

export default AdGroupNameInput;
