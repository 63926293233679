import { IconButton, Radio } from "@mui/material";
import { FC } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import cn from "utils/cn";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import {
  CampaignStructureType,
  CreateFormState,
  TabType,
} from "pages/createAds/CreateAds.types";

import manualCampaignType1 from "assets/icons/manual_campaign_type_1.svg";
import manualCampaignType2 from "assets/icons/manual_campaign_type_2.svg";
import manualCampaignType3 from "assets/icons/manual_campaign_type_3.svg";
import autoCampaignType1 from "assets/icons/auto_campaign_type_1.svg";
import autoCampaignType2 from "assets/icons/auto_campaign_type_2.svg";
import autoCampaignType3 from "assets/icons/auto_campaign_type_3.svg";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#030229",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#030229",
    maxWidth: "400px",
    fontSize: "16px",
    padding: "10px",
  },
}));

interface Props {
  tabType: TabType;
}

const CampaignStructureOptions: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control } = useFormContext<CreateFormState>()

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center leading-6 gap-2">
        <span className="text-[#111827]  font-bold text-[16px]">
          Campaign Structure Options
        </span>
        <BootstrapTooltip
          title={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta saepe impedit vel deserunt nihil consectetur maxime enim perspiciatis dolore."
          }
          className="text-center"
        >
          <IconButton>
            <IoMdInformationCircleOutline className="h-5 w-5 text-[#237804]" />
          </IconButton>
        </BootstrapTooltip>
      </div>

      {/* Structure options */}
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        <Controller
          name={`${tabType}.campaignStructureType`}
          control={control}
          render={({ field }) => (
            <>
              <Option
                id={`${CampaignStructureType.TYPE_1}`}
                setSelectedOption={() => {
                  field.onChange(CampaignStructureType.TYPE_1);
                }}
                active={field?.value === CampaignStructureType.TYPE_1}
                img={
                  tabType === TabType.AUTO_CAMPAIGN
                    ? autoCampaignType1
                    : manualCampaignType1
                }
              />
              <Option
                id={`${CampaignStructureType.TYPE_2}`}
                setSelectedOption={() => {
                  field.onChange(CampaignStructureType.TYPE_2);
                }}
                active={field?.value === CampaignStructureType.TYPE_2}
                img={
                  tabType === TabType.AUTO_CAMPAIGN
                    ? autoCampaignType2
                    : manualCampaignType2
                }
              />
              <Option
                id={`${CampaignStructureType.TYPE_3}`}
                setSelectedOption={() => {
                  field.onChange(CampaignStructureType.TYPE_3);
                }}
                active={field?.value === CampaignStructureType.TYPE_3}
                img={
                  tabType === TabType.AUTO_CAMPAIGN
                    ? autoCampaignType3
                    : manualCampaignType3
                }
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

const Option = ({
  id,
  setSelectedOption,
  active,
  img,
}: {
  id: string;
  setSelectedOption: any;
  active: boolean;
  img: string;
}) => {
  return (
    <div
      className={cn(
        "p-4 rounded-[4px] border-[1px] grid grid-cols-12 cursor-pointer gap-5 ",
        active && "bg-[#EAFFEC]"
      )}
      onClick={() => {
        setSelectedOption();
      }}
    >
      <label
        htmlFor={id}
        className="col-span-11 cursor-pointer flex items-center"
      >
        <img src={img} alt="options" className="w-full h-[200px]" />
      </label>
      <div className="col-span-1">
        <Radio
          id={id}
          checked={active}
          sx={{
            color: "#6B7280",
            padding: "0px",
            margin: "0px",
            marginRight: "5px",
            "&.Mui-checked": {
              color: "#237804",
            },
          }}
        />
      </div>
    </div>
  );
};

export default CampaignStructureOptions;
