import deleteIcon from "assets/icons/delete_icon.svg";
import { FC } from "react";
import CreateFormStateTypes, { ProductTargetingCluasesInterface } from "pages/createAds/CreateAdsFormValidationSchema";
import { useFieldArray, useFormContext } from "react-hook-form";

interface PropsInterface {
  product: ProductTargetingCluasesInterface
}

const SelectedIndividualProductsRow: FC<PropsInterface> = ({ product }) => {
  /**-Hook Form-**/
  const { control, getValues } = useFormContext<CreateFormStateTypes>()
  const { remove } = useFieldArray({
    control: control,
    name: "manualCampaign.targetingClauses"
  })

  /**-Event Handlers-**/
  const handleRemoveProductTargetClick = (product: ProductTargetingCluasesInterface): void => {
    const index = getValues("manualCampaign.targetingClauses")?.findIndex(prod => prod?.id === product?.id && product?.expression?.[0]?.value === prod?.expression?.[0]?.value && product?.expression?.[0]?.type === prod?.expression?.[0]?.type);
    remove(index);
  }

  return (
    <div className="grid grid-cols-5">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px] flex gap-2">
        {/* product image */}
        <img
          src="/assets/images/book2.svg"
          alt="book"
          className="w-[35px] object-cover"
        />
        {/* product details */}
        <div className="flex flex-col justify-center gap-1  ">
          <h4 className="line-clamp-1 text-[#111827] !font-medium !m-0 leading-5 text-sm ">
            {product?.name}
          </h4>
          <div className="flex items-center gap-2 !m-0 font-normal leading-5 text-xs">
            {/* <span>Megan Miles Art</span> */}
            <span className="w-[8px] h-[8px] rounded-full bg-[#b1b8c4] block m-0 p-0" />
            <span>{product?.expression?.[0].value}</span>
          </div>
        </div>
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        {product?.expression?.[0].type}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <button
          type="button"
          onClick={() => handleRemoveProductTargetClick(product)}
        >
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedIndividualProductsRow;
