import { Checkbox, FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material';
import { useCreatePaymentUrlMutation, useGetBillingHistoryQuery, useGetPlansQuery, useMakeProSubscriptionMutation } from 'features/payment/paymentSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { useLocation, useNavigate } from 'react-router';
import cn from 'utils/cn';
import crownImg from "assets/images/crown.png"
import crownTransparentImg from "assets/images/crown_transparent.png"
import { useLoggedInUserQuery } from 'features/user/userSlice';
import SubmitButton from 'components/submitButton/SubmitButton';
import BasicModal from 'components/basicModal/BasicModal';
import visaLogo from "assets/images/visa_logo_2.png"

interface BillingType {
    amount: string,
    billingId: string,
    description: string,
    id: string,
    issuedDate: string,
    paymentMethod: string,
    planName: string,
    billingFrequency,
}

type ActiveSubscriptionType = { [key: string]: any } | BillingType

const Subscription = () => {
    /**-React Router-**/
    const { state: routerState } = useLocation()
    const navigate = useNavigate()

    /**-React Hooks-**/
    //states
    const { data: loggedInUserData } = useLoggedInUserQuery("");
    const [showProModal, setShowProModal] = useState(false);
    const [showSelectPaymentMethodModal, setShowSelectPaymentMethodProModal] = useState(false);
    const [proSubscription, setProSubscription] = useState<ActiveSubscriptionType>({})

    /**-RTK-**/
    //queries
    const { data: planData, isLoading: planDataLoadin } = useGetPlansQuery("")
    //mutations
    const [createPaymentUrl, { isLoading }] = useCreatePaymentUrlMutation()
    const [makeProSubscription, { isLoading: makeProSubscriptionLoading }] = useMakeProSubscriptionMutation()
    //queries
    const { data: billingHistoryData, isFetching: billingHistoryDataFetching } = useGetBillingHistoryQuery({})

    /**-Hook Form-**/
    const { handleSubmit, control, watch, setValue, register, getValues, reset: resetProSubscriptionForm } = useForm({
        defaultValues: {
            upgradePlan: "free",
            interval: 'Monthly',
            amount: 39,
            currency: "USD",
            description: "Pro Version",
            withPaymentMethod: false
        }
    })
    const selectedPlan = watch("upgradePlan")
    const selectedInterval = watch("interval")

    /**-Utils-**/
    const processSubscription = async (planData?: { [key: string]: unknown }): Promise<void> => {
        try {
            const planDataFromForm = getValues()
            const upgradePlanData = {
                planId: planDataFromForm?.interval
            }

            if (planDataFromForm?.withPaymentMethod) {
                const { success } = await makeProSubscription(upgradePlanData).unwrap()
                if (success) {
                    setShowSelectPaymentMethodProModal(false)
                    navigate("/subscription/payment/success")
                }
            } else {
                const createdPaymentUrl = await createPaymentUrl(upgradePlanData).unwrap()
                window.location.href = createdPaymentUrl
            }
        } catch (error) {
            console.log(error);
        }
    }

    function getNextPaymentDate(billingInfo: ActiveSubscriptionType) {
        const issuedDate = new Date(billingInfo?.issuedDate);
        const interval = billingInfo.billingFrequency === "Monthly" ? 'monthly' : 'yearly';

        if (interval === 'monthly') {
            issuedDate.setMonth(issuedDate.getMonth() + 1);
        } else if (interval === 'yearly') {
            issuedDate.setFullYear(issuedDate.getFullYear() + 1);
        }

        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).format(issuedDate);

        return formattedDate;
    }

    /**-Event Handlers-**/
    const handleUpgradePlanSubmit = (data) => {
        if (loggedInUserData?.result?.paymentMethods?.length) {
            setShowProModal(false)
            setValue("withPaymentMethod", true)
            setShowSelectPaymentMethodProModal(true)
        } else {
            processSubscription()
        }
    }

    /**-useMemo-**/
    useMemo(() => {
        if (billingHistoryData?.result) {
            const proSubscriptionFound = billingHistoryData?.result?.data && billingHistoryData?.result?.data?.find(bill => bill?.isActive && (bill?.billingFrequency === "Monthly" || bill?.billingFrequency === "Yearly"))
            setProSubscription(proSubscriptionFound)
        }
    }, [billingHistoryData])

    /**-UseEffect-**/
    useEffect(() => {
        if (selectedPlan === 'pro' && planData?.result?.length) {
            const plan = planData?.result?.find(plan => plan?.price?.priceType === "Monthly")
            setValue('interval', plan?.id);
        } else {
            setValue('interval', '');
            setValue("amount", 0);
        }
    }, [selectedPlan, setValue, planData]);

    useEffect(() => {
        if (selectedInterval === 'month') {
            setValue("amount", 39);
        }
        if (selectedInterval === 'year') {
            setValue("amount", 399);
        }
    }, [selectedInterval, setValue])

    useEffect(() => {
        if (routerState?.showPlanUpgradeModal) {
            setShowProModal(true)
        }
    }, [routerState])

    if (billingHistoryDataFetching) {
        return <div className='bg-gray-200 border border-gray-200 p-6 rounded-md grid grid-cols-12 gap-3 h-40 animate-pulse'></div>
    }

    return (
        <div className='border border-gray-200 p-6 rounded-md grid grid-cols-12 gap-3'>
            <div className="col-span-6 grid gap-3 2xl:gap-6">
                {
                    proSubscription && Object.keys(proSubscription)?.length
                        ?
                        <div className='grid gap-2'>
                            <div className="grow flex gap-1 items-center">
                                <img className="w-[30px]" src={crownTransparentImg} alt="" />
                                <p className={cn(
                                    "text-xl font-medium text-black",
                                )}
                                >
                                    PRO Version
                                </p>
                            </div>
                            <p className={cn(
                                "flex gap-0.5 items-center text-black",
                            )}
                            >
                                <span className="text-[32px] font-bold">${proSubscription?.amount}</span><span className="text-[24px] font-medium mt-1 text-am-aiGray">/{proSubscription?.billingFrequency === "Monthly" ? "month" : proSubscription?.billingFrequency ? "Yearly" : "year"}</span>
                            </p>
                        </div>
                        :
                        <>
                            <p className="text-xl text-black font-medium">Free Plan</p>
                            <div className="flex gap-1 items-center">
                                <p className="mt-0 text-black text-3xl font-bold">$0</p>
                                <p className="mt-0 text-2xl font-medium text-gray-500">/month</p>
                            </div>
                        </>
                }
            </div>
            <div className="col-span-6 flex justify-end items-center">
                {
                    proSubscription && Object.keys(proSubscription)?.length
                        ?
                        <>
                            <button
                                className="bg-am-primary text-white p-3 rounded font-bold text-base tracking-wide"
                                // type='button'
                                onClick={() => setShowProModal(true)}
                            >
                                Cancel Subscription
                            </button>
                        </>
                        :
                        <button
                            onClick={() => setShowProModal(true)}
                            className="bg-am-primary text-white p-3 rounded font-bold text-base tracking-wide"
                        >
                            Upgrade to PRO Version
                        </button>
                }
            </div>
            {
                proSubscription && Object.keys(proSubscription)?.length
                    ?
                    <p className="col-span-12 font-medium">Your bext payment {getNextPaymentDate(proSubscription)}</p>
                    :
                    <p className="col-span-12 font-medium">Free plan for monthly royalties below $500. <span className="text-[#00976D]">Upgrade Plan</span> to access all Aimosa features.</p>
            }
            {/* upgrade plan modal */}
            <Modal
                open={showProModal}
                onClose={() => setShowProModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 min-w-80 max-w-[500px] bg-white rounded-md flex flex-col">
                    <header className="border-b border-gray-200 p-3">
                        <div className="flex justify-between">
                            <p className="text-xl font-bold">Upgrage to PRO Version</p>
                            <button onClick={() => setShowProModal(false)}>
                                <RxCross2 />
                            </button>
                        </div>
                    </header>
                    <form
                        onSubmit={handleSubmit(handleUpgradePlanSubmit)}
                        className="grow p-3 grid gap-4"
                    >
                        <section>
                            <Controller
                                control={control}
                                name="upgradePlan"
                                defaultValue={"free"}
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <div className="grid grid-cols-12 gap-3">
                                            <label
                                                htmlFor="amFree"
                                                className={cn(
                                                    "col-span-6 cursor-pointer border rounded p-3 grid gap-2",
                                                    value === "free" && "bg-[#EAFFEC] border-am-primary"
                                                )}
                                            >
                                                <div className="flex justify-between">
                                                    <p className={cn(
                                                        "text-xl font-semibold",
                                                        value === "free" ? "text-am-primary" : "text-black"
                                                    )}
                                                    >
                                                        Basic Plan
                                                    </p>
                                                    <Radio
                                                        id="amFree"
                                                        name="upgradePlan"
                                                        value="free"
                                                        inputProps={{ 'aria-label': 'free' }}
                                                        onChange={onChange}
                                                        checked={value === 'free'}
                                                        sx={{
                                                            padding: "0px",
                                                            color: "gray !important",
                                                            '&.Mui-checked': {
                                                                color: "green !important",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className={cn(
                                                        "text-3xl font-bold",
                                                        value === "free" ? "text-am-primary" : "text-black"
                                                    )}>FREE</p>
                                                    <p className={cn(
                                                        "mt-0 text-xs text-gray-600 tracking-wide border-b pr-2 pb-1",
                                                        value === "free" ? "border-am-primary-light/50" : "border-gray-200"
                                                    )}>Free plan for monthly royalties below $500</p>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li className="flex gap-2 items-center">
                                                            <FaCheck className="text-lg" />
                                                            <p>Benefit 1</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </label>
                                            <label
                                                htmlFor="amPro"
                                                className={cn(
                                                    "col-span-6 cursor-pointer border rounded p-3 grid gap-2",
                                                    value === "pro" && "bg-[#EAFFEC] border-am-primary"
                                                )}
                                            >
                                                <div className="flex">
                                                    <div className="grow flex gap-1 items-center">
                                                        <img className="w-[30px]" src={crownImg} alt="" />
                                                        <p className={cn(
                                                            "text-xl font-semibold",
                                                            value === "pro" ? "text-am-primary" : "text-black"
                                                        )}
                                                        >
                                                            PRO Version
                                                        </p>
                                                    </div>
                                                    <Radio
                                                        id="amPro"
                                                        name="upgradePlan"
                                                        value="pro"
                                                        inputProps={{ 'aria-label': 'pro' }}
                                                        checked={value === 'pro'}
                                                        onChange={onChange}
                                                        sx={{
                                                            padding: "0px",
                                                            color: "gray !important",
                                                            '&.Mui-checked': {
                                                                color: "green !important",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="grid gap-2">
                                                    <p className={cn(
                                                        "flex items-center",
                                                        value === "pro" ? "text-am-primary" : "text-black"
                                                    )}
                                                    >
                                                        <span className="text-3xl font-bold">$39</span><span className="text-[16px] font-semibold mt-1">/month</span>
                                                    </p>
                                                    <div
                                                        className={cn(
                                                            "flex justify-between items-center border-b pb-1",
                                                            value === "pro" ? "border-am-primary-light/50" : "border-gray-200"
                                                        )}
                                                    >
                                                        <p className={cn(
                                                            "flex items-center",
                                                            value === "pro" ? "text-am-primary" : "text-black"
                                                        )}
                                                        >
                                                            <span className="text-xl font-bold">$399</span><span className="text-xs font-semibold mt-1">/year</span>
                                                        </p>
                                                        <div>
                                                            <div className="bg-gradient-to-r from-[#FFAD0E] to-[#FF820E] pl-3 pr-2 py-1 translate-x-[12px]" style={{ clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)" }}>
                                                                <p className="text-white font-bold text-xs">Save up to 15%</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li className="flex gap-2 items-center">
                                                            <FaCheck className="text-lg" />
                                                            <p>Benefit 1</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                }}
                            />
                        </section>
                        {
                            (selectedPlan === "pro" && !planDataLoadin) && (
                                <section>
                                    <p className="text-[16px] font-bold">Duration</p>
                                    <Controller
                                        control={control}
                                        name="interval"
                                        render={({ field: { onChange, onBlur, value, ref } }) => {
                                            return (
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    {
                                                        planData?.result?.map(plan => (
                                                            <FormControlLabel
                                                                key={plan?.id}
                                                                value={plan?.id}
                                                                control={
                                                                    <Radio color="success" onChange={onChange} checked={value === plan?.id} />
                                                                }
                                                                label={`${plan?.price?.priceType} - $${plan?.price?.amount}`}
                                                            />
                                                        ))
                                                    }
                                                </RadioGroup>
                                            )
                                        }}
                                    />
                                </section>
                            )
                        }
                        <section className="flex justify-end">
                            <div className="flex gap-2">
                                <button
                                    type="button"
                                    className="px-8 py-2 tracking-wide font-bold text-xs text-am-primary border border-am-primary rounded"
                                    onClick={() => {
                                        resetProSubscriptionForm()
                                        setShowProModal(false)
                                    }}
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    buttonText="Go to Payment"
                                    className="px-8 py-2 tracking-wide font-bold text-xs"
                                    disabledClassName="px-8 py-2 tracking-wide font-bold text-xs"
                                    loadingClassName="px-8 py-2 tracking-wide font-bold text-xs"
                                    loaderSize={15}
                                    isDisabled={watch("upgradePlan") === "pro" ? false : true}
                                    isLoading={isLoading}
                                    buttonType="submit"
                                />
                            </div>
                        </section>
                    </form>
                </div>
            </Modal>
            {/* select payment method modal */}
            <BasicModal
                showModal={showSelectPaymentMethodModal}
                setShowModal={setShowSelectPaymentMethodProModal}
                headerTitle="Payment Details"
            >
                <div className="flex flex-col gap-6">
                    <div className="grid gap-y-3">
                        <p className="text-black text-base font-medium">Payment Method</p>
                        <div className="flex items-center gap-2">
                            <Checkbox
                                id="selectPaymentMethodCheckbox"
                                size="small"
                                sx={{
                                    '&.MuiCheckbox-root': {
                                        padding: "0px"
                                    },
                                    '&.Mui-checked': {
                                        color: "green !important",
                                    },
                                }}
                                checked={watch("withPaymentMethod")}
                                {...register("withPaymentMethod")}
                            />
                            <label htmlFor="selectPaymentMethodCheckbox" className="grid grid-flow-col gap-2 cursor-pointer">
                                <img className="w-[50px]" src={visaLogo} alt="" />
                                <p className="flex gap-1 text-am-aiGray text-xs">
                                    <span>****</span>
                                    <span>****</span>
                                    <span>****</span>
                                    <span>{loggedInUserData?.result?.paymentMethods?.[0]?.last4}</span>
                                </p>
                            </label>
                        </div>
                    </div>
                    <p className="col-span-12 font-medium">Free plan for monthly royalties below $500. <span className="text-[#00976D]">Upgrade Plan</span> to access all Aimosa features.</p>
                </div>
                <section className="flex justify-end mt-4">
                    <div className="flex gap-2">
                        <button
                            type="button"
                            className="px-8 py-2 tracking-wide font-bold text-xs text-am-primary border border-am-primary rounded"
                            onClick={() => {
                                resetProSubscriptionForm()
                                setShowSelectPaymentMethodProModal(false)
                            }}
                        >
                            Cancel
                        </button>
                        <SubmitButton
                            buttonText="Pay Now"
                            className="px-8 py-2 tracking-wide font-bold text-xs"
                            disabledClassName="px-8 py-2 tracking-wide font-bold text-xs"
                            loadingClassName="px-8 py-2 tracking-wide font-bold text-xs"
                            loaderSize={15}
                            isLoading={makeProSubscriptionLoading || isLoading}
                            onClick={processSubscription}
                            buttonType="button"
                        />
                    </div>
                </section>
            </BasicModal>
        </div>
    );
};

export default Subscription;