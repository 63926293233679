import { IoSettingsSharp } from "react-icons/io5";
import cn from "utils/cn";
import { BsCurrencyDollar } from "react-icons/bs";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import {
  TabType,
} from "pages/createAds/CreateAds.types";
import { FC } from "react";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import CreateFormStateTypes from "pages/createAds/CreateAdsFormValidationSchema";

interface Props {
  tabType: TabType;
}

// static options to render
const bidingStrategyOptions = [{ label: "Down only", amName: "LEGACY_FOR_SALES" }, { label: "Up and Down", amName: "AUTO_FOR_SALES" }, { label: "Fixed", amName: "FIXED" },]

const BidSettings: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control } = useFormContext<CreateFormStateTypes>()

  return (
    <div className="w-full flex flex-col gap-5">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <button type="button" className="text-[#111827]">
          <IoSettingsSharp className="h-5 w-5" />
        </button>
        <span className="text-[#111827] font-bold text-xl">Bid Settings</span>
      </div>
      {/* inputs options */}
      <div className="w-full grid grid-cols-1 md:grid-cols-11 gap-6">
        {/*campaign bid strategy inputs*/}
        <div className="md:col-span-8 lg:col-span-6 xl:col-span-4 flex gap-2 flex-col">
          <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
            Compaign Bidding Strategy
          </h4>
          <Controller
            name={`${tabType}.dynamicBidding.strategy`}
            control={control}
            render={({ field }) => {
              return (
                <div className="grid grid-cols-3 gap-4">
                  {
                    bidingStrategyOptions?.map((strategy, index) => (
                      <div key={index} className="w-full">
                        <button
                          type="button"
                          className={cn(
                            "rounded-[4px] py-2 border-[1px] w-full",
                            field.value === strategy.amName &&
                            "border-[#237804] bg-[#EAFFEC] text-[#237804] !font-bold"
                          )}
                          onClick={() => {
                            field?.onChange(strategy?.amName);
                          }}
                        >
                          {strategy?.label}
                        </button>
                      </div>
                    ))
                  }
                </div>
              )
            }}
          />
        </div>

        {/* daily budget inputs */}
        <div className="md:col-span-3 lg:col-span-4 xl:col-span-2 flex gap-2 flex-col">
          <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
            Daily Budget
          </h4>
          <Controller
            name={`${tabType}.budget.budgetAmount`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <div className="w-full relative">
                  <span className="absolute top-[50%] translate-y-[-50%] left-[1px] px-3.5 py-[10px] rounded-l-[4px] bg-[#F3F4F6] text-[#111827] ">
                    <BsCurrencyDollar className="h-4 w-4" />
                  </span>
                  <input
                    onChange={(e) => {
                      field?.onChange(Number(e.target.value));
                    }}
                    value={field.value || ""}
                    type="number"
                    placeholder="Enter daily budget"
                    className="outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pl-12 hover:border-[#237804] focus:border-[#237804]"
                  />
                </div>
                {error?.message && <ErrorLabel message={error?.message} />}
              </div>
            )}
          />
        </div>

        {/* default bid stretegy */}
        <div className="md:col-span-11 lg:col-span-5 xl:col-span-3 flex gap-2 flex-col">
          <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
            Default Bid
          </h4>
          <Controller
            name={`${tabType}.defaultBid`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                <input
                  onChange={(e) => {
                    field?.onChange(Number(e.target.value));
                  }}
                  value={field.value}
                  placeholder="Enter default bid"
                  className="outline-none border-[1px] py-2 px-4 rounded-[4px] w-full hover:border-[#237804] focus:border-[#237804]"
                  type="number"
                  min={0.02}
                />
                {error?.message && <ErrorLabel message={error?.message} />}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default BidSettings;
