import SubmitButton from "components/submitButton/SubmitButton";
import { IoSyncSharp } from "react-icons/io5";
import { MdChevronLeft } from "react-icons/md";
import cn from "utils/cn";
import { TabType } from "./CreateAds.types";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import CreateAdsAutoCampaignTab from "./createAdsAutoCampaignTab/CreateAdsAutoCampaignTab";
import CreateAdsManualCampaignTab from "./createAdsManualCampaignTab/CreateAdsManualCampaignTab";
import Warning from "./Warning";
import CreateFormStateTypes, { createAdsValidationSchema } from "./CreateAdsFormValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateCampaignMutation } from "features/campaign/campaignSlice";
import dayjs from "dayjs";

type FilledForType = "Auto" | "Manual" | "Both"

const CreateAds = () => {
  /**-React Hooks-**/
  //states
  const [activeTab, setActiveTab] = useState<TabType>(TabType.AUTO_CAMPAIGN);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [dataFilledFor, setDataFilledFor] = useState<"Auto" | "Manual" | "">("")

  /**-RTK-**/
  //mutations
  const [createCampaign, { isLoading: createCampaignLoading }] = useCreateCampaignMutation()

  /* Hook form */
  const methods = useForm<CreateFormStateTypes>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      keywordMatchTypesFilters: { broad: true, phrase: true, exact: true },
      productMatchTypesFilters: ["exact", "expanded"],
      autoCampaign: {
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        campaignStructureType: "SingleCampaignWithSingleAdgroup",
        budget: {
          budgetType: "DAILY"
        },
        noEndDate: false,
      },
      manualCampaign: {
        types: { keywordsTargeting: true, productTargeting: true },
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        campaignStructureType: "SingleCampaignWithSingleAdgroup",
        budget: {
          budgetType: "DAILY"
        },
        noEndDate: false,
        keywordTargetsMultiplier: {
          broad: { selected: true },
          phrase: { selected: true },
          exact: { selected: true },
          product: { selected: true },
          category: { selected: true }
        },
      },
    },
    resolver: yupResolver(createAdsValidationSchema)
  });

  const { getValues, formState: { errors } } = methods

  /**--Event handlers --**/
  const handleCreateAddSubmit: SubmitHandler<CreateFormStateTypes> = (data) => {
    console.log("formSubmitSuccess", data)
    handleContinueCreatingCampaign("Both")
  };

  const handleContinueCreatingCampaign = async (filledFor: FilledForType) => {
    const formData = getValues()
    console.log(filledFor, formData)
    if (filledFor === "Auto") {
      const { campaignStructureType } = formData?.autoCampaign
      const campaigns: any = { campaigns: [] }
      switch (campaignStructureType) {
        case "SingleCampaignWithSingleAdgroup":
          const autoCampaignData = formData?.autoCampaign
          let autoCampaign: any = {
            targetingType: "AUTO",
            name: autoCampaignData?.campaignName?.name,
            startDate: autoCampaignData?.startDate ? dayjs(autoCampaignData?.startDate).format("YYYY-MM-DDTHH:mm:ssZ[Z]") : null,
            endDate: autoCampaignData?.endDate ? dayjs(autoCampaignData?.endDate).format("YYYY-MM-DDTHH:mm:ssZ[Z]") : null,
            defaultBid: autoCampaignData?.defaultBid,
            customBid: autoCampaignData?.customBid,
            marketPlaces: [
              {
                profileId: "2475159079111070",
                portfolioId: "73874278171636",
                countryCode: "US"
              }
            ],
            dynamicBidding: autoCampaignData?.dynamicBidding,
            budget: autoCampaignData?.budget,
            autoCampaignMatchTypes: autoCampaignData?.autoCampaignMatchTypes,
            asin: ["B07S2ZB4DL"],
            keywordTargets: [],
            targetingClauses: [],
            negativeKeywords: null,
            negativeProducts: null,
            isCreated: true
          };
          let adGroups: any = []
          Array.from({ length: 2 }).forEach((_) => {
            const adGroup = {
              name: autoCampaignData?.adGroupName?.name,
              defaultBid: autoCampaignData.defaultBid
            }
            adGroups.push(adGroup)
          })
          autoCampaign.adGroups = adGroups
          campaigns.campaigns.push(autoCampaign)
          break;
        default:
          console.log("Please Select A Campaign Structure.")
          break;
      }
      console.log("createAdsPlayload", campaigns)
      try {
        const createdCampaign = await createCampaign(campaigns).unwrap()
        console.log("createdCampaign", createdCampaign)
      } catch (error) {
        console.log("autoCampaign", formData?.autoCampaign)
        console.log("campaignCreationErrors", error)
      }
    } else if (filledFor === "Manual") {
      console.log("finalFormData", formData)
    } else {

    }
  }

  /**--useEffect--**/
  useEffect(() => {
    if (Object.keys(errors).length) {
      //checking manual campaign data is provided or not
      if (activeTab === TabType.AUTO_CAMPAIGN && !errors?.autoCampaign) {
        if (errors?.manualCampaign) {
          setDataFilledFor("Auto")
          setWarningOpen(true) //if manual campaign data is not provided then showing a warning modal
        }
      }
      //checking auto campaign data is provided or not
      if (activeTab === TabType.MANUAL_CAMPAIGN && !errors?.manualCampaign) {
        if (errors?.autoCampaign) {
          setDataFilledFor("Manual")
          setWarningOpen(true) //if auto campaign data is not provided then showing a warning modal
        }
      }
    }
  }, [errors, activeTab])

  console.log("formValues", getValues())
  console.log("formErrors", errors)

  return (
    <div className="flex flex-col gap-4">
      {/* back and sync button */}
      <div className="flex items-center justify-between bg-white py-2 px-3 ">
        {/* back button */}
        <button
          type="button"
          className=" capitalize flex items-center gap-1 text-[#6B7280] text-sm !font-bold leading-5"
        >
          <MdChevronLeft className="h-5 w-5" />
          <span>create ads</span>
        </button>
        <div className="flex items-center gap-2 ">
          <button type="button" className="!font-bold text-[#6B7280]">
            <IoSyncSharp className="h-5 w-5" />
          </button>
          <span className="text-[#6B7280] text-xs !font-bold leading-5 capitalize">
            last app sync
          </span>
          <span className="text-[#6B7280] text-xs !font-normal leading-5">
            Wed, Nov 2, 10:57
          </span>
        </div>
      </div>
      {/* main content box */}
      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(handleCreateAddSubmit)} className="flex flex-col bg-white rounded-[4px]">
          {/* page switching buttons */}
          <div className="flex flex-row flex-wrap gap-6 border-b  px-4">
            <button
              type="button"
              className={cn(
                "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
                activeTab === TabType.AUTO_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
              )}
              onClick={() => setActiveTab(TabType.AUTO_CAMPAIGN)}
            >
              Auto Campaign
            </button>

            <button
              type="button"
              className={cn(
                "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
                activeTab === TabType.MANUAL_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
              )}
              onClick={() => setActiveTab(TabType.MANUAL_CAMPAIGN)}
            >
              Manual Campaign
            </button>
          </div>
          {/* content */}
          <div className="p-4">
            <div className="flex flex-col gap-5">
              {/* tab content */}
              {activeTab === TabType.AUTO_CAMPAIGN ? (
                <CreateAdsAutoCampaignTab
                  tabType={TabType.AUTO_CAMPAIGN}
                />
              ) : (
                <CreateAdsManualCampaignTab
                  tabType={TabType.MANUAL_CAMPAIGN}
                />
              )}

              {/* form action button */}
              <div className="flex items-center justify-end gap-5">
                <button
                  type="button"
                  className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                >
                  back
                </button>
                <div>
                  <SubmitButton
                    buttonText="Finish"
                    buttonType="submit"
                    className="px-8"
                    loadingClassName="px-8"
                    disabledClassName="px-8"
                    // isDisabled={!isValid}
                    isLoading={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      {/* warning model */}
      <Warning
        open={warningOpen}
        setOpen={setWarningOpen}
        setActiveTab={setActiveTab}
        dataFilledFor={dataFilledFor}
        handleContinue={() => handleContinueCreatingCampaign(dataFilledFor === "Auto" ? "Auto" : "Manual")}
        isLoading={createCampaignLoading}
      />
    </div>
  );
};

export default CreateAds;
