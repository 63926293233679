import BillingHistoryTable from "./BillingHistoryTable";
import { useState } from "react";
import { useGetBillingHistoryQuery } from "features/payment/paymentSlice";
import BasicModal from "components/basicModal/BasicModal";
import AddStripePaymentMethod from "./AddStripePaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLoggedInUserQuery } from "features/user/userSlice";
import AddedPaymentMethod from "./AddedPaymentMethod";
import visaLogo from "assets/images/visa_logo_2.png"
import Subscription from "./Subscription";

const stripePromise = loadStripe('pk_test_51LQPkBFopl4E3uahxoWNSIIFhKksjYDnSoHjmwTFgylDsZ9trt60MZfkFxKd2zgmX0xLLY6xzUjeUlTIVuQLWKI6007tq2R1Sr');

const BillingAndPlan = () => {
  /**-React Hooks-**/
  //states
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

  /**-RTK-**/
  //queries
  const { data: loggedInUserData } = useLoggedInUserQuery("");
  const { data: billingHistoryData, isLoading: billingHistoryDataLoading } = useGetBillingHistoryQuery({})

  return (
    <section className="grow flex flex-col gap-6">
      <h2 className="border-b border-gray-200 !leading-8 !font-bold !text-xl text-blacks m-0 pb-3">Billing & Plan</h2>
      <div className="grid grid-cols-12 gap-4">
        <div className="lg:col-span-7">
          <Subscription />
        </div>
        <div className="lg:col-span-5 border border-gray-200 p-6 rounded-md flex flex-col">
          <div className="grid grid-cols-12">
            <div className="lg:col-span-6">
              <p className="text-black text-base font-medium">Payment Method</p>
            </div>
            <div className="lg:col-span-6 flex justify-end">
              {
                loggedInUserData?.result?.paymentMethods?.length
                  ?
                  <div>
                    <button
                      className="hover:no-underline text-current inline-block"
                    >
                      <p className="underline underline-offset-4 !decoration-2 !decoration-am-primary text-am-primary font-bold">Edit Payment Method</p>
                    </button>
                    <AddedPaymentMethod />
                  </div>
                  :
                  <div>
                    {
                      loggedInUserData?.result?.subscriptions?.[0]?.plan !== "FreePlan"
                        ?
                        <button
                          className="hover:no-underline text-current inline-block"
                          onClick={() => setShowAddPaymentMethodModal(true)}
                        >
                          <p className="underline underline-offset-4 !decoration-2 !decoration-am-primary text-am-primary font-bold">Add Payment Method</p>
                        </button>
                        :
                        <p className="underline underline-offset-4 !decoration-2 !decoration-gray-300 text-gray-400 font-bold">Add Payment Method</p>
                    }
                    {/* Add payment method modal */}
                    <BasicModal
                      showModal={showAddPaymentMethodModal}
                      setShowModal={setShowAddPaymentMethodModal}
                      headerTitle="Add Payment Method"
                    >
                      <Elements stripe={stripePromise}>
                        <AddStripePaymentMethod
                          handleAddPaymentMethodClose={showAddPaymentMethodModal}
                        />
                      </Elements>
                    </BasicModal>
                  </div>
              }
            </div>
          </div>
          {/* added payment method details */}
          <div className="grow">
            {
              loggedInUserData?.result?.paymentMethods?.length
                ?
                <div className="pt-3 h-full flex flex-col justify-between">
                  <div>
                    <img className="w-[74px]" src={visaLogo} alt="" />
                  </div>
                  <div className="flex justify-between text-am-aiGray font-medium">
                    <div className="">
                      <p className="flex gap-1">
                        <span>****</span>
                        <span>****</span>
                        <span>****</span>
                        <span>{loggedInUserData?.result?.paymentMethods?.[0]?.last4}</span>
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <p>Exp</p>
                      <p className="mt-0">{loggedInUserData?.result?.paymentMethods?.[0]?.expMonth}/{loggedInUserData?.result?.paymentMethods?.[0]?.expYear?.toString()?.split("")?.slice(-2)?.join("")}</p>
                    </div>
                  </div>
                </div>
                :
                <div className="h-full grid place-items-center">
                  <p className="font-medium text-am-aiGray">No Payment Method Added</p>
                </div>
            }
          </div>
        </div>
      </div>
      {/* Billing history table */}
      <div className="grow">
        {
          !billingHistoryDataLoading &&
          <BillingHistoryTable billingHistoryData={billingHistoryData?.result?.data || []} />
        }
      </div>
    </section>
  )
};

export default BillingAndPlan;
