import { useMemo } from 'react';
import {
    MaterialReactTable as MrtTable,
    type MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { type BillingHistoryType } from './exampleDummyTableData';
import { download, generateCsv, mkConfig } from 'export-to-csv';

export const BillingHistoryTable: React.FC<{ billingHistoryData: BillingHistoryType[] }> = ({ billingHistoryData }) => {
    /**-CSV Config-**/
    const csvConfig = mkConfig({
        filename: "Aimosa Billing History",
        columnHeaders: [
            { key: "id", displayLabel: "ID" },
            { key: "paymentMethod", displayLabel: "Payment Method" },
            { key: "description", displayLabel: "Plan" },
            { key: "issuedDate", displayLabel: "Issued Date" },
            { key: "amount", displayLabel: "Amount" }
        ],
        quoteCharacter: `'`,
        quoteStrings: true,
    });

    /**-Event Handlers-**/
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(billingHistoryData);
        download(csvConfig)(csv);
    };

    /**-MT Table-**/
    const columns = useMemo<MRT_ColumnDef<BillingHistoryType>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'paymentMethod',
                header: 'Payment Method',
            },
            {
                accessorKey: 'planName',
                header: 'Plan',
            },
            {
                accessorKey: 'issuedDate',
                header: 'Issued Date',
                Cell: ({ cell }) => {
                    return new Date(cell.getValue<string>()).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                }
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
            },
        ],
        [],
    );

    const table = useMaterialReactTable<BillingHistoryType>({
        columns,
        data: billingHistoryData,
        enableGlobalFilterModes: true,
        initialState: {
            showGlobalFilter: true,
        },
        positionGlobalFilter: 'left',
        enableColumnFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableSorting: false,

        renderTopToolbarCustomActions: ({ table }) => (
            <div className='w-full flex justify-end'>
                <button onClick={handleExportData} className="underline underline-offset-4 !decoration-2 !decoration-am-primary text-am-primary font-bold">Download .CSV</button>
            </div>
        ),

        muiPaginationProps: {
            // color: 'primary',
            shape: 'rounded',
            variant: 'outlined',
            sx: {
                '& .Mui-selected': {
                    color: "white !important",
                    backgroundColor: "#237804"
                }
            },
        },
        paginationDisplayMode: 'pages',
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        muiTableBodyRowProps: { hover: false },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableProps: {
            sx: {
                border: '1px solid #e5e7eb',
                caption: {
                    captionSide: 'top',
                },
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
                fontWeight: 'normal',
                backgroundColor: '#F9FAFB',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
            },
        },
    });

    return <MrtTable table={table} />
};

export default BillingHistoryTable;