const DefaultBid = 0.02
enum KeywordTargetsMatchTypes {
    "broad" = "Broad",
    "phrase" = "Phrase",
    "exact" = "Exact"
}


export {
    DefaultBid,
    KeywordTargetsMatchTypes
}