import styled from "@emotion/styled";
import KeywordRow from "./KeywordRow";
import SelectedKeywordRow from "./SelectedKeywordRow";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CreateFormStateTypes, { KeywordMatchTypesFiltersTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import ErrorLabel from "components/errorLabel/ErrorLabel";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

export type MatchType = { name: "broad" | "phrase" | "exact" }
export interface RecommendedKeywordInterface {
  id: number,
  keyword: string,
  matchTypes?: MatchType[]
}

const typeFilters: { name: keyof KeywordMatchTypesFiltersTypes, label: string }[] = [{ name: "broad", label: "Broad" }, { name: "phrase", label: "Phrase" }, { name: "exact", label: "Exact" },]

const recommendedKeywordsFromApi: RecommendedKeywordInterface[] = [
  { id: 1, keyword: "Keyword 1" },
  { id: 2, keyword: "Keyword 2" },
  { id: 3, keyword: "Keyword 3" },
  { id: 4, keyword: "Keyword 4" },
  { id: 5, keyword: "Keyword 5" },
  { id: 6, keyword: "Keyword 6" },
  { id: 7, keyword: "Keyword 7" },
]

const KeywordTargetingInputs = () => {
  /**-React Hooks-**/
  //states
  const [recommendedKeywords, setRecommendedKeywords] = useState<RecommendedKeywordInterface[]>()

  /**-Hook Form-**/
  const { register, watch, formState: { errors } } = useFormContext<CreateFormStateTypes>()
  const broad = watch("keywordMatchTypesFilters.broad")
  const phrase = watch("keywordMatchTypesFilters.phrase")
  const exact = watch("keywordMatchTypesFilters.exact")

  /**-useEffect-**/
  useEffect(() => {
    let matchTypes: MatchType[] = [];
    let recommendedKeywordsCopy = recommendedKeywordsFromApi
    if (broad) {
      matchTypes.push({ name: "broad" })
    }
    if (phrase) {
      matchTypes.push({ name: "phrase" })
    }
    if (exact) {
      matchTypes.push({ name: "exact" })
    }

    if (matchTypes?.length) {
      recommendedKeywordsCopy = recommendedKeywordsFromApi?.map(keyword => {
        delete keyword.matchTypes
        keyword.matchTypes = matchTypes
        return keyword
      })
    } else {
      recommendedKeywordsCopy = recommendedKeywordsFromApi?.map(keyword => {
        delete keyword.matchTypes
        return keyword
      })
    }
    setRecommendedKeywords(recommendedKeywordsCopy)
  }, [broad, phrase, exact])

  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <span className="text-[#111827] font-bold text-[16px]">
          Keyword Targeting
        </span>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        {/* left box */}
        <div className="flex flex-col gap-2 h-full ">
          {/* box header */}
          <div className="w-full flex flex-col gap-2">
            {/* type input */}
            <div className="flex gap-x-6 items-center flex-wrap">
              {/* type selection */}
              <div className="w-full flex flex-col gap-2">
                <h4 className="text-[#111827] leading-6 font-bold text-[16px] !m-0">
                  Types
                </h4>
                <div className="flex gap-4">
                  {
                    typeFilters?.map((filter, index) => (
                      <div key={index} className="flex items-center justify-start gap-2">
                        <input
                          id={`keywordMatchTypesFilters.${filter.name}`}
                          type="checkbox"
                          className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                          {...register(`keywordMatchTypesFilters.${filter.name}`)}
                        />
                        <label htmlFor={`keywordMatchTypesFilters.${filter.name}`} className="!text-[#111827] text-[16px] !font-normal leading-6">
                          {filter?.label}
                        </label>
                      </div>
                    ))
                  }
                </div>
                {errors?.manualCampaign?.keywordTargets?.message && <ErrorLabel message={errors?.manualCampaign?.keywordTargets?.message} />}
              </div>
            </div>
          </div>
          {/* keywords inputs button */}
          <div className="w-full flex flex-col gap-4">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[500px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-3">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Keyword
                </div>

                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Match Type
                </div>
                <div className="font-medium leading-6 text-[16px] text-center text-[#237804]">
                  Add All
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                {
                  recommendedKeywords?.map((keyword, index) => (
                    <KeywordRow
                      key={index}
                      parentIndex={index}
                      keywordData={keyword}
                    />
                  ))
                }
              </ContentBoxContainer>
            </div>
          </div>
        </div>
        {/* right blox */}
        <div className="flex flex-col gap-2 h-full items-end justify-end">
          <div className="w-full flex justify-between">
            <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
              0/1000 keyword added
            </p>
            <button
              type="button"
              className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
            >
              Remove all
            </button>
          </div>
          {/* keywords inputs button */}
          <div className="w-full flex flex-col">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[500px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-3">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Keyword
                </div>
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Match Type
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                {
                  watch("manualCampaign.keywordTargets")?.map((keyword, index) => (
                    <SelectedKeywordRow
                      key={index}
                      keyword={keyword}
                    />
                  ))
                }

                {/* <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow /> */}
              </ContentBoxContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordTargetingInputs;
