import React from 'react';
import { MatchType, RecommendedKeywordInterface } from './KeywordTargetingInputs';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { GoCheckCircleFill } from 'react-icons/go';
import { handleKeywordTargetSelectionManipulationType } from './KeywordRow';
import { Checkbox } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CreateFormStateTypes from 'pages/createAds/CreateAdsFormValidationSchema';

interface MatchTypeSelectionInterface {
    typeData: MatchType,
    parentData: RecommendedKeywordInterface
    handleKeywordTargetSelectionManipulation: handleKeywordTargetSelectionManipulationType
}

const MatchTypeSelection: React.FC<MatchTypeSelectionInterface> = ({ typeData, parentData, handleKeywordTargetSelectionManipulation }) => {
    /**-Hook Form-**/
    const { watch } = useFormContext<CreateFormStateTypes>()
    const watchKeywordTargets = watch("manualCampaign.keywordTargets")
    const checked = watchKeywordTargets?.some((target) => target?.id === parentData?.id && target?.matchType?.toLowerCase() === typeData?.name?.toLowerCase())
    return (
        <div className="grid grid-cols-2">
            <div className="text-[#111827] font-normal leading-6 text-[16px] flex flex-col">
                <span className="capitalize">{typeData.name}</span>
            </div>
            <div className="text-[#237804] font-normal leading-6 text-[16px] text-center">
                <Checkbox
                    icon={<IoIosAddCircleOutline />}
                    checkedIcon={<GoCheckCircleFill />}
                    onChange={() => handleKeywordTargetSelectionManipulation(typeData, parentData)}
                    checked={checked || false}
                    sx={{
                        '&.Mui-checked': {
                            color: "green"
                        }
                    }}
                    size='large'
                />
            </div>
        </div>
    );
};

export default MatchTypeSelection;