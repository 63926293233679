import { TabType } from "pages/createAds/CreateAds.types";
import CreateFormStateTypes, { PlacementTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import { BsPercent } from "react-icons/bs";
import { PiListChecksFill } from "react-icons/pi";
import cn from "utils/cn";

interface Props {
  tabType: TabType;
}

type PlacementOptionTypes = {
  label: string,
  amName: PlacementTypes
}

const placementOptions: PlacementOptionTypes[] = [
  { label: "Top of search (first page)", amName: "PLACEMENT_TOP" },
  { label: "Product detail pages", amName: "PLACEMENT_PRODUCT_PAGE" },
  { label: "Rest of search", amName: "PLACEMENT_REST_OF_SEARCH" }
]

const PlacementInputs: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control, register } = useFormContext<CreateFormStateTypes>()
  return (
    <>
      <div className="w-full flex flex-col gap-2">
        {/* header */}
        <div className="flex flex-row items-center leading-6 gap-2">
          <button type="button" className="text-[#111827]">
            <PiListChecksFill className="h-5 w-5" />
          </button>
          <span className="text-[#111827] font-bold text-xl">Placement</span>
        </div>
        {/* input options */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
          {
            placementOptions?.map((placement, index) => {
              return (
                <Controller
                  key={index}
                  name={`${tabType}.dynamicBidding.placementBidding.${index}.percentage`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className=" flex gap-2 flex-col">
                      <div className="w-full">
                        <div className="inline-flex items-center gap-2 ">
                          <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6" {...register(`${tabType}.dynamicBidding.placementBidding.${index}.placement`, { value: placement?.amName })}>
                            {placement?.label}
                          </h4>
                        </div>
                      </div>
                      <div className="w-full relative">
                        <span
                          className={cn(
                            "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[10px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                            fieldState?.error && "border-l border-red-500"
                          )}
                        >
                          <BsPercent className="h-4 w-4" />
                        </span>
                        <input
                          onChange={(e) => {
                            field?.onChange(Number(e.target.value));
                          }}
                          value={field?.value || ""}
                          type="number"
                          placeholder="percentage between 0-900"
                          className={cn(
                            "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                            fieldState?.error && "!border-red-500"
                          )}
                        />
                      </div>

                      {fieldState?.error && <p>{fieldState?.error?.message}</p>}
                    </div>
                  )}
                />
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default PlacementInputs;
