import React from "react";
import { MatchType, RecommendedKeywordInterface } from "./KeywordTargetingInputs";
import MatchTypeSelection from "./MatchTypeSelection";
import { useFieldArray, useFormContext } from "react-hook-form";
import CreateFormStateTypes, { KeywordTargetsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { DefaultBid, KeywordTargetsMatchTypes } from "constants/index";

interface KeywordRowInterface {
  parentIndex: number,
  keywordData: RecommendedKeywordInterface
}

export type handleKeywordTargetSelectionManipulationType = (selectedType: MatchType, parentData: RecommendedKeywordInterface) => void

const KeywordRow: React.FC<KeywordRowInterface> = ({ keywordData }) => {
  /**-Hook Form-**/
  const { control, watch, getValues } = useFormContext<CreateFormStateTypes>()
  const watchKeywordTargets = watch("manualCampaign.keywordTargets")
  const { append, remove } = useFieldArray({
    control,
    name: "manualCampaign.keywordTargets"
  })

  /**-Event Handlers-**/
  const handleKeywordTargetSelectionManipulation: handleKeywordTargetSelectionManipulationType = (selectedType, parentData) => {
    const matchTypeMatched = watchKeywordTargets?.some((target) => target?.id === parentData?.id && target?.matchType?.toLowerCase() === selectedType?.name?.toLowerCase())

    if (matchTypeMatched) {
      const index = watchKeywordTargets?.findIndex((target) => target?.id === parentData?.id && target?.matchType?.toLowerCase() === selectedType?.name?.toLowerCase());
      remove(index);
    } else {
      const selectedMatchType = (selectedType?.name?.toLocaleLowerCase()).toLowerCase()
      let bid = Number(getValues("manualCampaign.customBid")) || Number(getValues("manualCampaign.defaultBid")) || DefaultBid
      const multiplierPath = `manualCampaign.keywordTargetsMultiplier.[${selectedMatchType}].value` as keyof CreateFormStateTypes;
      const multiplier2 = Number(getValues(multiplierPath))
      if (multiplier2 && selectedMatchType === (KeywordTargetsMatchTypes[`${selectedMatchType}`]).toLowerCase()) {
        bid = bid * (multiplier2 || 1)
      }

      const newTarget: KeywordTargetsTypes = {
        id: parentData?.id,
        matchType: selectedType?.name?.toUpperCase(),
        bid: bid,
        keywordText: parentData?.keyword
      }
      append(newTarget);
    }
  }

  return (
    <div className="grid grid-cols-3 border-b">
      <div className=" text-[#111827] font-normal leading-6 text-[16px] flex gap-2">
        {keywordData?.keyword}
      </div>
      <div className=" col-span-2 flex flex-col gap-2 ">
        {
          keywordData?.matchTypes?.map((type, index) => (
            <MatchTypeSelection
              key={index}
              typeData={type}
              parentData={keywordData}
              handleKeywordTargetSelectionManipulation={handleKeywordTargetSelectionManipulation}
            />
          ))
        }
      </div>
    </div>
  );
};

export default KeywordRow;
