import deleteIcon from "assets/icons/delete_icon.svg";
import CreateFormStateTypes, { ProductTargetingCluasesInterface } from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface SelectedCategoryProductRowInterface {
  category: ProductTargetingCluasesInterface
}

const SelectedCategoryProductRow: FC<SelectedCategoryProductRowInterface> = ({ category }) => {
  /**-Hook Form-**/
  const { control, getValues } = useFormContext<CreateFormStateTypes>()
  const { remove } = useFieldArray({
    control: control,
    name: "manualCampaign.targetingClauses"
  })

  /**-Event Handlers-**/
  const handleRemoveCategoryTargetClick = (category: ProductTargetingCluasesInterface): void => {
    const index = getValues("manualCampaign.targetingClauses")?.findIndex(productCat => productCat?.id === category?.id && category?.expression?.[0]?.value === productCat?.expression?.[0]?.value);
    remove(index);
  }
  return (
    <div className="grid grid-cols-4">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px]">
        {category?.name}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <button
          onClick={() => handleRemoveCategoryTargetClick(category)}
          type="button"
        >
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedCategoryProductRow;
