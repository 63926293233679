import { Checkbox } from "@mui/material";
import CreateFormStateTypes, { ProductTargetingExpressionsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { GoCheckCircleFill } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CategoryInterface } from "./CategoriesTab";

interface CategoryRowPropsInterface {
  category: CategoryInterface,
  handleCategorySelectionChange: (catgory: CategoryInterface) => void
}

const CategoryRow: FC<CategoryRowPropsInterface> = ({ category, handleCategorySelectionChange }) => {
  /**-Hook Form-**/
  const { watch } = useFormContext<CreateFormStateTypes>()
  const watchProductCatTargets = watch("manualCampaign.targetingClauses")
  const checked = watchProductCatTargets?.some((categoryTarget) => categoryTarget?.id === category?.id && categoryTarget?.expression?.[0]?.type === ProductTargetingExpressionsTypes.ASIN_CATEGORY_SAME_AS)

  return (
    <div className="grid grid-cols-4">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px]">
        {category?.name}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <Checkbox
          icon={<IoIosAddCircleOutline />}
          checkedIcon={<GoCheckCircleFill />}
          onChange={() => handleCategorySelectionChange(category)}
          checked={checked || false}
          sx={{
            '&.Mui-checked': {
              color: "green"
            }
          }}
          size='large'
        />
      </div>
    </div>
  );
};

export default CategoryRow;
